const config = {
  apiKey: 'AIzaSyBRmrtRrbqLIPpKHejNJqotdNCo-3ocRvs',
  authDomain: 'travel2bali.firebaseapp.com',
  projectId: 'travel2bali',
  storageBucket: 'travel2bali.appspot.com',
  messagingSenderId: '498503094068',
  appId: '1:498503094068:web:3a36b0f194eca6d2705f61',
  measurementId: 'G-2PWCL37LGD',
};

export default config;
