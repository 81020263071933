import { Link } from 'react-router-dom';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em',
  },
};

const GenericAddRelatedButton = ({
  originResource,
  originField,
  originId,
  targetResource,
  targetField,
}: {
  classes: any;
  originResource: string | undefined;
  originField: string | undefined;
  originId: string | undefined;
  targetResource: string | undefined;
  targetField: string | undefined;
}) => (
  <Button
    variant='text'
    component={Link}
    to={`/${targetResource}/create?${originField}=${originId}&next=/${originResource}/${originId}/show`}
    label={`Add ${targetField ? targetField.replace('_', ' ') : ''}`}
    title={`Add ${targetField ? targetField.replace('_', ' ') : ''}`}
  >
    <AddCircleOutline />
  </Button>
);

export default withStyles(styles)(GenericAddRelatedButton);
