import {
  Show,
  SimpleShowLayout,
  TextField,
  ShowProps,
  useAuthState,
  Loading,
  ListButton,
  TopToolbar,
  ShowActionsProps,
  ReferenceManyField,
  Datagrid,
  DateField,
  Pagination,
  NumberField,
  CloneButton,
  DeleteButton,
  RowClickFunction,
  Record,
  Identifier,
  FunctionField,
} from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import AddRelatedButton from '../generic/AddRelatedButton';

const Actions = (props: ShowActionsProps) => (
  <TopToolbar>
    <ListButton basePath={props.basePath} label='Back' icon={<ChevronLeft />} />
  </TopToolbar>
);

const onRowClick: RowClickFunction = (
  id: Identifier,
  basePath: string,
  record: Record,
) => {
  return `${basePath}/${id}?next=/hotels/${record.hotel}/show`;
};

const ExcursionShow = (props: ShowProps) => {
  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  return (
    <Show {...props} actions={<Actions />}>
      <SimpleShowLayout>
        <TextField source='title' />

        <ReferenceManyField
          reference='excursion_prices'
          target='excursion'
          label='Prices'
          sort={{ field: 'from_date', order: 'ASC' }}
          pagination={<Pagination />}
          perPage={25}
          fullWidth
        >
          <Datagrid rowClick={onRowClick}>
            <DateField source='from_date' label='From date' locales='en-GB' />
            <DateField source='to_date' label='To date' locales='en-GB' />

            <FunctionField
              source='price_one_adult'
              label='Price one adult / child'
              render={(record: any) =>
                `${record.price_one_adult
                  .toLocaleString()
                  .replace('.', ',')} / ${record.price_one_child
                  .toLocaleString()
                  .replace('.', ',')}`
              }
              textAlign='right'
            />
            <FunctionField
              source='price_two_adults'
              label='Price two adults / children'
              render={(record: any) =>
                `${record.price_two_adults
                  .toLocaleString()
                  .replace('.', ',')} / ${record.price_two_children
                  .toLocaleString()
                  .replace('.', ',')}`
              }
              textAlign='right'
            />
            <FunctionField
              source='price_three_adults'
              label='Price three adults / children'
              render={(record: any) =>
                `${record.price_three_adults
                  .toLocaleString()
                  .replace('.', ',')} / ${record.price_three_children
                  .toLocaleString()
                  .replace('.', ',')}`
              }
              textAlign='right'
            />
            <FunctionField
              source='price_four_adults'
              label='Price four adults / children'
              render={(record: any) =>
                `${record.price_four_adults
                  .toLocaleString()
                  .replace('.', ',')} / ${record.price_four_children
                  .toLocaleString()
                  .replace('.', ',')}`
              }
              textAlign='right'
            />
            <FunctionField
              source='price_five_or_more_adults'
              label='Price five or more adults / children'
              render={(record: any) =>
                `${record.price_five_or_more_adults
                  .toLocaleString()
                  .replace('.', ',')} / ${record.price_five_or_more_children
                  .toLocaleString()
                  .replace('.', ',')}`
              }
              textAlign='right'
            />

            <NumberField
              source='variable_advance_per_person'
              label='Advance in % per person'
            />
            <NumberField
              source='fixed_advance_per_person'
              label='Fixed advance per person'
            />

            <CloneButton label='' />
            <DeleteButton
              label=''
              mutationMode='optimistic'
              redirect={`${props.basePath}/${props.id}/show`}
            />
          </Datagrid>
        </ReferenceManyField>

        <AddRelatedButton
          originResource='excursions'
          originField='excursion'
          originId={props.id}
          targetResource='excursion_prices'
          targetField='excursion_price'
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default ExcursionShow;
