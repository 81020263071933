import { email, required, SimpleForm, TextInput } from 'react-admin';

const UserFormFields = (props: any) => {
  return (
    <>
      <TextInput
        source='id'
        label='Email'
        validate={[required(), email()]}
        fullWidth
      />
    </>
  );
};

const UserForm = (props: any) => {
  return (
    <SimpleForm {...props} warnWhenUnsavedChanges sanitizeEmptyValues={false}>
      <UserFormFields />
    </SimpleForm>
  );
};

export default UserForm;
export { UserForm, UserFormFields };
