import {
  Create,
  CreateActionsProps,
  CreateProps,
  ListButton,
  Loading,
  TopToolbar,
  useAuthState,
} from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import FlightForm from './FlightForm';

const Actions = (props: CreateActionsProps) => (
  <TopToolbar>
    <ListButton basePath={props.basePath} label='Back' icon={<ChevronLeft />} />
  </TopToolbar>
);

const FlightCreate = (props: CreateProps) => {
  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  return (
    <Create {...props} actions={<Actions />}>
      <FlightForm {...props} />
    </Create>
  );
};

export default FlightCreate;
