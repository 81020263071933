import Card from '@material-ui/core/Card';
import CardContent, { CardContentProps } from '@material-ui/core/CardContent';
import { Loading, Title, useAuthState } from 'react-admin';

const Dashboard = (props: CardContentProps) => {
  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  return (
    <Card>
      <Title title='Dashboard' />
      <CardContent>What to put on the home page?</CardContent>
    </Card>
  );
};

export default Dashboard;
