import {
  AppBar,
  AppBarProps,
  DashboardMenuItem,
  Layout,
  LayoutProps,
  MenuItemLink,
  getResources,
} from 'react-admin';
import { useSelector } from 'react-redux';
import DefaultIcon from '@material-ui/icons/ViewList';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';
import SyncAltIcon from '@material-ui/icons/SyncAlt';

const CustomAppBar = (props: AppBarProps) => (
  <AppBar {...props} color='primary' />
);

const CustomMenu = () => {
  const resources = useSelector(getResources);

  return (
    <div style={{ paddingTop: '10px' }}>
      <DashboardMenuItem />

      {resources.map((resource) =>
        resource.name !== 'settings' && resource.name !== 'destinations' ? (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
          />
        ) : (
          ''
        ),
      )}

      <MenuItemLink
        key='settings'
        to='/settings/settings'
        primaryText='Settings'
        leftIcon={<SettingsIcon />}
      />

      <MenuItemLink
        key='wordpress'
        to='/wordpress/synchronize-data'
        primaryText='Wordpress'
        leftIcon={<SyncAltIcon />}
      />

      <MenuItemLink
        key='deployment-booking-site'
        to='/deployment/booking-site'
        primaryText='Booking site'
        leftIcon={<SendIcon />}
      />
    </div>
  );
};

const CustomLayout = (props: LayoutProps) => (
  <Layout {...props} appBar={CustomAppBar} menu={CustomMenu} />
);

export default CustomLayout;
