import { BooleanInput, SimpleForm, TextField } from 'react-admin';

const HotelFormFields = (props: any) => {
  return (
    <>
      <TextField source='title' label='Title' fullWidth />
      <BooleanInput source='visible' label='Visible' defaultChecked={true} defaultValue={true} fullWidth />
    </>
  );
};

const HotelForm = (props: any) => {
  return (
    <SimpleForm {...props} warnWhenUnsavedChanges sanitizeEmptyValues={false}>
      <HotelFormFields />
    </SimpleForm>
  );
};

export default HotelForm;
export { HotelForm, HotelFormFields };
