import {
  List,
  Datagrid,
  DatagridProps,
  useAuthState,
  Loading,
  DateField,
  NumberField,
  DateInput,
  downloadCSV,
  SelectField,
  SelectInput,
  CloneButton,
} from 'react-admin';
import { useMediaQuery, useTheme } from '@material-ui/core';
import jsonExport from 'jsonexport/dist';

const exporter = (records: any[]) => {
  jsonExport(records, { rowDelimiter: ';' }, (err, csv) => {
    downloadCSV(csv, 'flights');
  });
};

const filters = [
  <SelectInput
    source='type'
    label='Type'
    choices={[
      { id: 'period', name: 'Period' },
      { id: 'scheduled_trip', name: 'Scheduled trip' },
    ]}
    alwaysOn
  />,
  <DateInput source='from_date' label='From date' />,
  <DateInput source='to_date' label='To date' />,
];

const FlightList = (props: DatagridProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  if (isSmall) {
    return (
      <List {...props} sort={{ field: 'from_date', order: 'ASC' }}>
        <Datagrid rowClick='edit'>
          <SelectField
            source='type'
            label='Type'
            choices={[
              { id: 'period', name: 'Period' },
              { id: 'scheduled_trip', name: 'Scheduled trip' },
            ]}
          />
          <DateField source='from_date' label='From date' locales='en-GB' />
        </Datagrid>
      </List>
    );
  }

  if (isMedium) {
    return (
      <List
        {...props}
        sort={{ field: 'from_date', order: 'ASC' }}
        filters={filters}
        exporter={exporter}
        perPage={25}
      >
        <Datagrid rowClick='edit'>
          <SelectField
            source='type'
            label='Type'
            choices={[
              { id: 'period', name: 'Period' },
              { id: 'scheduled_trip', name: 'Scheduled trip' },
            ]}
          />
          <DateField source='from_date' label='From date' locales='en-GB' />
          <DateField source='to_date' label='To date' locales='en-GB' />
          <NumberField source='price_per_adult' label='Price per adult' />
          <NumberField source='price_per_child' label='Price per child' />
        </Datagrid>
      </List>
    );
  }

  return (
    <List
      {...props}
      sort={{ field: 'from_date', order: 'ASC' }}
      filters={filters}
      exporter={exporter}
      perPage={25}
    >
      <Datagrid rowClick='edit'>
        <SelectField
          source='type'
          label='Type'
          choices={[
            { id: 'period', name: 'Period' },
            { id: 'scheduled_trip', name: 'Scheduled trip' },
          ]}
        />
        <DateField source='from_date' label='From date' locales='en-GB' />
        <DateField source='to_date' label='To date' locales='en-GB' />
        <NumberField source='price_per_adult' label='Price per adult' />
        <NumberField source='price_per_child' label='Price per child' />
        <NumberField
          source='variable_advance_per_person'
          label='Advance in % per person'
        />
        <NumberField
          source='fixed_advance_per_person'
          label='Fixed advance per person'
        />

        <CloneButton basePath={props.basePath} label='' />
      </Datagrid>
    </List>
  );
};

export default FlightList;
