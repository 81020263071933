import { Edit, EditActionsProps, EditProps, ListButton, Loading, ShowButton, TopToolbar, useAuthState } from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import HotelForm from './HotelForm';

const Actions = (props: EditActionsProps) => (
  <TopToolbar>
    <ListButton basePath={props.basePath} label='Back' icon={<ChevronLeft />} />
    <ShowButton basePath={props.basePath} record={props.data} />
  </TopToolbar>
);

const HotelEdit = (props: EditProps) => {
  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  return (
    <Edit {...props} mutationMode='optimistic' actions={<Actions />}>
      <HotelForm {...props} />
    </Edit>
  );
};

export default HotelEdit;
