import {
  Edit,
  EditActionsProps,
  EditProps,
  ListButton,
  Loading,
  TopToolbar,
  useAuthState,
} from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import SightseeingPriceForm from './SightseeingPriceForm';

const Actions = (props: EditActionsProps) => (
  <TopToolbar>
    <ListButton basePath={props.basePath} label='Back' icon={<ChevronLeft />} />
  </TopToolbar>
);

const SightseeingPriceEdit = (props: EditProps) => {
  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  return (
    <Edit {...props} mutationMode='optimistic' actions={<Actions />}>
      <SightseeingPriceForm {...props} />
    </Edit>
  );
};

export default SightseeingPriceEdit;
