import {
  Edit,
  EditActionsProps,
  EditProps,
  ListButton,
  Loading,
  NumberInput,
  required,
  Toolbar,
  TopToolbar,
  useAuthState,
  SaveButton,
  TabbedForm,
  FormTab,
  minValue,
  TextInput,
} from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

const Actions = (props: EditActionsProps) => (
  <TopToolbar>
    <ListButton basePath={props.basePath} label='Back' icon={<ChevronLeft />} />
  </TopToolbar>
);

const UserEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const SettingEdit = (props: EditProps) => {
  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  return (
    <Edit {...props} mutationMode='optimistic' actions={<Actions />}>
      <TabbedForm warnWhenUnsavedChanges sanitizeEmptyValues={false} redirect={false} toolbar={<UserEditToolbar />}>
        <FormTab label='General'>
          <NumberInput
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='usd_exchange_rate'
            label='USD exchange rate'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='fallback_flight_prices.price_per_adult'
            label='Fallback flight price per adult'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='fallback_flight_prices.price_per_child'
            label='Fallback flight price per child'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
        </FormTab>
        <FormTab label='Help Texts'>
          <TextInput source='help_text_trip' label='Trip' validate={[required()]} fullWidth />
          <TextInput source='help_text_persons' label='Persons' validate={[required()]} fullWidth />
          <TextInput source='help_text_calendar_scheduled_trip' label='Calendar scheduled trip' validate={[required()]} fullWidth />
          <TextInput source='help_text_calendar_scheduled_trip_2' label='Calendar scheduled trip 2' validate={[required()]} fullWidth />
          <TextInput source='help_text_calendar_period' label='Calendar period' validate={[required()]} fullWidth />
          <TextInput source='help_text_calendar_period_2' label='Calendar period 2' validate={[required()]} fullWidth />
          <TextInput source='help_text_hotels' label='Hotels' validate={[required()]} fullWidth />
          <TextInput source='help_text_excursions' label='Excursions' validate={[required()]} fullWidth />
          <TextInput source='help_text_confirm' label='Confirm' validate={[required()]} fullWidth />
        </FormTab>

        {/* <FormTab label='Default Flight Settings'>
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_flight_settings.price_per_adult'
            label='Price per adult'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_flight_settings.price_per_child'
            label='Price per child'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />

          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_flight_settings.variable_advance_per_person'
            label='Advance in % per person'
            validate={[required(), minValue(0), maxValue(100)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_flight_settings.fixed_advance_per_person'
            label='Fixed advance per person'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
        </FormTab>

        <FormTab label='Default Hotel Settings'>
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='price_one_adult'
            label='Price one adult'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='price_two_adults'
            label='Price two adults'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='price_extra_adults'
            label='Price extra adults'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='price_extra_children'
            label='Price extra children'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='room_capacity'
            label='Room capacity'
            validate={[required(), minValue(0)]}
            fullWidth
            step={1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='variable_advance_per_person'
            label='Advance in % per person'
            validate={[required(), minValue(0), maxValue(100)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='fixed_advance_per_person'
            label='Fixed advance per person'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
        </FormTab>

        <FormTab label='Default Excursion Settings'>
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_excursion_settings.price_one_person'
            label='Price one person'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_excursion_settings.price_two_persons'
            label='Price two persons'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_excursion_settings.price_three_persons'
            label='Price three persons'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_excursion_settings.price_four_persons'
            label='Price four persons'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_excursion_settings.price_five_or_more_persons'
            label='Price five or more persons'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_excursion_settings.variable_advance_per_person'
            label='Advance in % per person'
            validate={[required(), minValue(0), maxValue(100)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_excursion_settings.fixed_advance_per_person'
            label='Fixed advance per person'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
        </FormTab>

        <FormTab label='Default Sightseeing Settings'>
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_sightseeing_settings.price_one_person'
            label='Price one person'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_sightseeing_settings.price_two_persons'
            label='Price two persons'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_sightseeing_settings.price_three_persons'
            label='Price three persons'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_sightseeing_settings.price_four_persons'
            label='Price four persons'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_sightseeing_settings.price_five_or_more_persons'
            label='Price five or more persons'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_sightseeing_settings.variable_advance_per_person'
            label='Advance in % per person'
            validate={[required(), minValue(0), maxValue(100)]}
            fullWidth
            step={0.1}
          />
          <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
            source='default_sightseeing_settings.fixed_advance_per_person'
            label='Fixed advance per person'
            validate={[required(), minValue(0)]}
            fullWidth
            step={0.1}
          />
        </FormTab> */}
      </TabbedForm>
    </Edit>
  );
};

export default SettingEdit;
