import {
  Show,
  TextField,
  useAuthState,
  Loading,
  ListButton,
  TopToolbar,
  ShowActionsProps,
  Datagrid,
  NumberField,
  TabbedShowLayout,
  Tab,
  ArrayField,
  ChipField,
  SingleFieldList,
  ReferenceField,
  ReferenceArrayField,
  FunctionField,
  DateField,
  Labeled,
} from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

const calcFlightAdvance = (flight: any) => {
  let totalAdvance = 0;

  const fixedAdvance = (flight.adults + flight.children) * flight.price_fixed_advance_per_person;
  const variableAdvanceAdults = flight.adults * ((flight.price_per_adult * flight.price_variable_advance_per_person) / 100);
  const variableAdvanceChildren = flight.children * ((flight.price_per_child * flight.price_variable_advance_per_person) / 100);
  totalAdvance +=
    (fixedAdvance + variableAdvanceAdults + variableAdvanceChildren) * (flight.price_usd_exchange_rate !== 0 ? flight.price_usd_exchange_rate : 1);

  return totalAdvance;
};

const calcSightseeingsAdvance = (sightseeings: any) => {
  let totalAdvance = 0;

  for (let i = 0; i < sightseeings.length; i++) {
    const sightseeing = sightseeings[i];
    const fixedAdvance = (sightseeing.adults + sightseeing.children) * sightseeing.price_fixed_advance_per_person;
    const variableAdvanceAdults = sightseeing.adults * ((sightseeing.price_per_adult * sightseeing.price_variable_advance_per_person) / 100);
    const variableAdvanceChildren = sightseeing.children * ((sightseeing.price_per_child * sightseeing.price_variable_advance_per_person) / 100);
    totalAdvance +=
      (fixedAdvance + variableAdvanceAdults + variableAdvanceChildren) * (sightseeing.price_usd_exchange_rate !== 0 ? sightseeing.price_usd_exchange_rate : 1);
  }
  return totalAdvance;
};

const calcHotelsAdvance = (hotels: any) => {
  let totalAdvance = 0;

  for (let i = 0; i < hotels.length; i++) {
    const hotel = hotels[i];

    for (let j = 0; j < hotel.nights.length; j++) {
      const night = hotel.nights[j];

      for (let k = 0; k < night.rooms.length; k++) {
        const room = night.rooms[k];

        let priceAdults = 0;
        if (hotel.adults === 1) {
          priceAdults = room.price_one_adult;
        } else if (hotel.adults >= 2) {
          priceAdults = room.price_two_adults * 2;
        }
        if (hotel.adults > 2) {
          priceAdults += room.price_extra_adults * (hotel.adults - 2);
        }

        const fixedAdvance = (hotel.adults + hotel.children) * room.price_fixed_advance_per_person;
        const variableAdvanceAdults = (priceAdults * room.price_variable_advance_per_person) / 100;
        const variableAdvanceChildren = hotel.children * ((room.price_extra_children * room.price_variable_advance_per_person) / 100);

        const nightAdvance =
          (fixedAdvance + variableAdvanceAdults + variableAdvanceChildren) * (room.price_usd_exchange_rate !== 0 ? room.price_usd_exchange_rate : 1);

        totalAdvance += nightAdvance;
      }
    }
  }

  return totalAdvance;
};

const calcExcursionsAdvance = (excursions: any) => {
  let totalAdvance = 0;

  for (let i = 0; i < excursions.length; i++) {
    const excursion = excursions[i];
    const fixedAdvance = (excursion.adults + excursion.children) * excursion.price_fixed_advance_per_person;
    const variableAdvanceAdults = excursion.adults * ((excursion.price_per_adult * excursion.price_variable_advance_per_person) / 100);
    const variableAdvanceChildren = excursion.children * ((excursion.price_per_child * excursion.price_variable_advance_per_person) / 100);
    totalAdvance +=
      (fixedAdvance + variableAdvanceAdults + variableAdvanceChildren) * (excursion.price_usd_exchange_rate !== 0 ? excursion.price_usd_exchange_rate : 1);
  }
  return totalAdvance;
};

const Actions = (props: ShowActionsProps) => (
  <TopToolbar>
    <ListButton basePath={props.basePath} label='Back' icon={<ChevronLeft />} />
  </TopToolbar>
);

const BookingSections = (props: any) => {
  return (
    <>
      <ArrayField source='trip.sections' label='Sections' fullWidth>
        <Datagrid>
          <FunctionField label='Type' render={(record: any) => record.type.charAt(0).toUpperCase() + record.type.slice(1)} />
          <FunctionField
            label='Title'
            render={(record: any) =>
              record.type === 'destination' ? (
                <ReferenceField source='destination' reference='destinations' label='Title' link={false}>
                  <TextField source='title' />
                </ReferenceField>
              ) : (
                <ReferenceField source='sightseeing' reference='sightseeings' label='Sightseeing' link={false}>
                  <TextField source='title' />
                </ReferenceField>
              )
            }
          />
          <ReferenceField source='hotel' reference='hotels' label='Hotel' link={false}>
            <TextField source='title' />
          </ReferenceField>
          <NumberField source='nights' label='Nights' />
          <ReferenceArrayField source='excursions' reference='excursions' label='Excursions' fullWidth>
            <SingleFieldList linkType={false}>
              <FunctionField
                label='Type'
                render={(record: any) =>
                  props.record.trip.excludedExcursions.includes(record.id) ? (
                    <ChipField
                      style={{
                        textDecorationLine: 'line-through',
                        textDecorationStyle: 'solid',
                      }}
                      source='title'
                    />
                  ) : (
                    <ChipField source='title' />
                  )
                }
              />
            </SingleFieldList>
          </ReferenceArrayField>
        </Datagrid>
      </ArrayField>
    </>
  );
};

const BookingAddedExcursions = (props: any) => {
  return (
    <>
      <ReferenceArrayField label='Excursions' reference='excursions' source='trip.addedExcursions'>
        <Datagrid>
          <TextField label='Title' source='title' />
        </Datagrid>
      </ReferenceArrayField>
    </>
  );
};

const BookingRooms = (props: any) => {
  return (
    <>
      <div style={{ fontSize: '14px' }}>
        <ol
          style={{
            marginLeft: '-24px',
            marginTop: '-2px',
            marginBottom: '2px',
          }}
        >
          {props.record.trip.roomsAdultsAllocated.map((item: any, i: any) => (
            <li key={i}>
              Room: {item} adults, {props.record.trip.roomsChildrenAllocated[i]} children
            </li>
          ))}
        </ol>
      </div>
    </>
  );
};

const BookingTotalPrices = (props: any) => {
  return (
    <>
      <div style={{ fontSize: '14px' }}>
        <div>
          Amount in total:{' '}
          {props.record.prices.sum.amount_in_total.toLocaleString('da-DK', {
            style: 'currency',
            minimumFractionDigits: 0,
            currency: 'DKK',
          })}
        </div>
        <div style={{ paddingTop: '4px' }}>
          Profit in total:{' '}
          {(
            calcFlightAdvance(props.record.prices.flight) +
            calcSightseeingsAdvance(props.record.prices.sightseeings) +
            calcHotelsAdvance(props.record.prices.hotels) +
            calcExcursionsAdvance(props.record.prices.excursions)
          ).toLocaleString('da-DK', {
            style: 'currency',
            minimumFractionDigits: 0,
            currency: 'DKK',
          })}
        </div>
        <div style={{ paddingTop: '4px' }}>
          Amount per person:{' '}
          {props.record.prices.sum.amount_per_person.toLocaleString('da-DK', {
            style: 'currency',
            minimumFractionDigits: 0,
            currency: 'DKK',
          })}
        </div>
        <div style={{ paddingTop: '4px' }}>
          Calculation: {props.record.prices.flight.amount.toString().replace(',', '')} +{' '}
          {props.record.prices.hotels.length
            ? props.record.prices.hotels
                .map((x: any) => x.amount)
                .reduce((a: any, b: any) => a + b)
                .toString()
                .replace(',', '')
            : '0'}{' '}
          +{' '}
          {props.record.prices.excursions.length
            ? props.record.prices.excursions
                .map((x: any) => x.amount)
                .reduce((a: any, b: any) => a + b)
                .toString()
                .replace(',', '')
            : '0'}{' '}
          +{' '}
          {props.record.prices.sightseeings.length
            ? props.record.prices.sightseeings
                .map((x: any) => x.amount)
                .reduce((a: any, b: any) => a + b)
                .toString()
                .replace(',', '')
            : '0'}
        </div>
      </div>
    </>
  );
};

const BookingFlightPrices = (props: any) => {
  return (
    <>
      <div style={{ fontSize: '14px' }}>
        <div>
          Amount:{' '}
          {props.record.prices.flight.amount.toLocaleString('da-DK', {
            style: 'currency',
            minimumFractionDigits: 0,
            currency: 'DKK',
          })}
        </div>
        <div style={{ paddingTop: '4px' }}>
          Profit:{' '}
          {calcFlightAdvance(props.record.prices.flight).toLocaleString('da-DK', {
            style: 'currency',
            minimumFractionDigits: 0,
            currency: 'DKK',
          })}
        </div>
        <div style={{ paddingTop: '4px' }}>Calculation: {props.record.prices.flight.calculation}</div>
      </div>
    </>
  );
};

const BookingHotelPricesNightRooms = (props: any) => {
  return (
    <>
      <ArrayField source='rooms' label='Rooms' fullWidth>
        <Datagrid>
          <FunctionField
            render={(record: any) =>
              record.amount.toLocaleString('da-DK', {
                style: 'currency',
                minimumFractionDigits: 0,
                currency: 'DKK',
              })
            }
            label='Amount'
          ></FunctionField>
          <NumberField source='calculation'></NumberField>
          <NumberField source='price_one_adult' label='One adult'></NumberField>
          <NumberField source='price_two_adults' label='Two adults'></NumberField>
          <NumberField source='price_extra_adults' label='Extra adults'></NumberField>
          <NumberField source='price_extra_children' label='Extra children'></NumberField>
          <NumberField source='price_fixed_advance_per_person' label='Fixed advance'></NumberField>
          <NumberField source='price_variable_advance_per_person' label='Variable advance'></NumberField>
          <NumberField source='price_usd_exchange_rate' label='Exchange rate'></NumberField>
        </Datagrid>
      </ArrayField>
    </>
  );
};

const BookingHotelPricesNights = (props: any) => {
  return (
    <>
      <ArrayField source='nights' label='Nights' fullWidth>
        <Datagrid expand={<BookingHotelPricesNightRooms />}>
          <DateField source='night' locales='en-GB'></DateField>
          <FunctionField
            render={(record: any) =>
              `${(record.rooms.length ? record.rooms.map((x: any) => x.amount).reduce((a: any, b: any) => a + b) : 0).toLocaleString('da-DK', {
                style: 'currency',
                minimumFractionDigits: 0,
                currency: 'DKK',
              })}`
            }
            label='Amount'
          ></FunctionField>
        </Datagrid>
      </ArrayField>
    </>
  );
};

const BookingHotelPrices = (props: any) => {
  return (
    <>
      <div style={{ fontSize: '14px' }}>
        <div>
          Amount:{' '}
          {(props.record.prices.hotels.length ? props.record.prices.hotels.map((x: any) => x.amount).reduce((a: any, b: any) => a + b) : 0).toLocaleString(
            'da-DK',
            {
              style: 'currency',
              minimumFractionDigits: 0,
              currency: 'DKK',
            }
          )}
        </div>
        <div style={{ paddingTop: '4px' }}>
          Profit:{' '}
          {calcHotelsAdvance(props.record.prices.hotels).toLocaleString('da-DK', {
            style: 'currency',
            minimumFractionDigits: 0,
            currency: 'DKK',
          })}
        </div>
      </div>
      <ArrayField source='prices.hotels' label='Hotels' fullWidth>
        <Datagrid expand={<BookingHotelPricesNights />}>
          <TextField source='title'></TextField>
          <FunctionField
            render={(record: any) =>
              record.amount.toLocaleString('da-DK', {
                style: 'currency',
                minimumFractionDigits: 0,
                currency: 'DKK',
              })
            }
            label='Amount'
          ></FunctionField>
          <DateField source='date' locales='en-GB'></DateField>
          <FunctionField render={(record: any) => `${record.nights.length}`} label='Nights'></FunctionField>
          <NumberField source='adults'></NumberField>
          <NumberField source='children'></NumberField>
        </Datagrid>
      </ArrayField>
    </>
  );
};

const BookingExcursionPrices = (props: any) => {
  return (
    <>
      <div style={{ fontSize: '14px' }}>
        <div>
          Amount:{' '}
          {(props.record.prices.excursions.length
            ? props.record.prices.excursions.map((x: any) => x.amount).reduce((a: any, b: any) => a + b)
            : 0
          ).toLocaleString('da-DK', {
            style: 'currency',
            minimumFractionDigits: 0,
            currency: 'DKK',
          })}
        </div>
        <div style={{ paddingTop: '4px' }}>
          Profit:{' '}
          {calcExcursionsAdvance(props.record.prices.excursions).toLocaleString('da-DK', {
            style: 'currency',
            minimumFractionDigits: 0,
            currency: 'DKK',
          })}
        </div>
      </div>
      <ArrayField source='prices.excursions' label='Excursions' fullWidth>
        <Datagrid>
          <TextField source='title'></TextField>
          <FunctionField
            render={(record: any) =>
              record.amount.toLocaleString('da-DK', {
                style: 'currency',
                minimumFractionDigits: 0,
                currency: 'DKK',
              })
            }
            label='Amount'
          ></FunctionField>
          <TextField source='calculation'></TextField>

          <DateField source='date' locales='en-GB'></DateField>
          <NumberField source='adults'></NumberField>
          <NumberField source='children'></NumberField>
          <NumberField source='price_per_adult' label='Per adult'></NumberField>
          <NumberField source='price_per_child' label='Per child'></NumberField>
          <NumberField source='price_fixed_advance_per_person' label='Fixed advance'></NumberField>
          <NumberField source='price_variable_advance_per_person' label='Variable advance'></NumberField>
          <NumberField source='price_usd_exchange_rate' label='Exchange rate'></NumberField>
        </Datagrid>
      </ArrayField>
    </>
  );
};

const BookingSightseeingPrices = (props: any) => {
  return (
    <>
      <div style={{ fontSize: '14px' }}>
        <div>
          Amount:{' '}
          {(props.record.prices.sightseeings.length
            ? props.record.prices.sightseeings.map((x: any) => x.amount).reduce((a: any, b: any) => a + b)
            : 0
          ).toLocaleString('da-DK', {
            style: 'currency',
            minimumFractionDigits: 0,
            currency: 'DKK',
          })}
        </div>
        <div style={{ paddingTop: '4px' }}>
          Profit:{' '}
          {calcSightseeingsAdvance(props.record.prices.sightseeings).toLocaleString('da-DK', {
            style: 'currency',
            minimumFractionDigits: 0,
            currency: 'DKK',
          })}
        </div>
      </div>
      <ArrayField source='prices.sightseeings' label='Sightseeings' fullWidth>
        <Datagrid>
          <TextField source='title'></TextField>
          <FunctionField
            render={(record: any) =>
              record.amount.toLocaleString('da-DK', {
                style: 'currency',
                minimumFractionDigits: 0,
                currency: 'DKK',
              })
            }
            label='Amount'
          ></FunctionField>
          <TextField source='calculation'></TextField>

          <DateField source='date' locales='en-GB'></DateField>
          <NumberField source='adults'></NumberField>
          <NumberField source='children'></NumberField>
          <NumberField source='price_per_adult' label='Per adult'></NumberField>
          <NumberField source='price_per_child' label='Per child'></NumberField>
          <NumberField source='price_fixed_advance_per_person' label='Fixed advance'></NumberField>
          <NumberField source='price_variable_advance_per_person' label='Variable advance'></NumberField>
          <NumberField source='price_usd_exchange_rate' label='Exchange rate'></NumberField>
        </Datagrid>
      </ArrayField>
    </>
  );
};

const BookingShow = (props: any) => {
  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  return (
    <Show {...props} actions={<Actions />}>
      <TabbedShowLayout>
        <Tab label='Contact info'>
          <TextField source='trip.contactInfo.first_name' label='First name' />
          <TextField source='trip.contactInfo.last_name' label='Last name' />
          <TextField source='trip.contactInfo.email' label='Email' />
          <TextField source='trip.contactInfo.phone_no' label='Phone No.' />
          <TextField source='trip.contactInfo.comment' label='Comment' component='pre' />
        </Tab>

        <Tab label='Trip' path='trip'>
          <TextField label='Trip' source='trip.title'></TextField>

          <NumberField source='trip.adults' label='Adults' />
          <NumberField source='trip.children' label='Children' />

          <Labeled label='Rooms' fullWidth={true}>
            <BookingRooms />
          </Labeled>

          <FunctionField label='Flight type' render={(record: any) => `${record.trip.departureType === 'scheduled_trip' ? 'Scheduled trip' : 'Period'}`} />

          <DateField source='trip.departureDate' label='Departure date' locales='en-GB' />
          <DateField source='trip.arrivalDate' label='Arrival date' locales='en-GB' />
          <NumberField source='trip.days' label='Days' />
          <NumberField source='trip.nights' label='Nights' />

          <Labeled label='Sections' fullWidth={true}>
            <BookingSections />
          </Labeled>

          <Labeled label='Added excursions' fullWidth={true}>
            <BookingAddedExcursions />
          </Labeled>
        </Tab>

        <Tab label='Price calc' path='price-calc'>
          <Labeled label='Price' fullWidth={true}>
            <BookingTotalPrices />
          </Labeled>

          <Labeled label='Flight prices' fullWidth={true}>
            <BookingFlightPrices />
          </Labeled>

          <Labeled label='Hotel prices' fullWidth={true}>
            <BookingHotelPrices />
          </Labeled>

          <Labeled label='Excursion prices' fullWidth={true}>
            <BookingExcursionPrices />
          </Labeled>

          <Labeled label='Sightseeing prices' fullWidth={true}>
            <BookingSightseeingPrices />
          </Labeled>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default BookingShow;
export { BookingSections, BookingShow };
