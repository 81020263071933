import { Login } from 'react-admin';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { LoginProps } from 'ra-ui-materialui/lib/auth/Login';

import firebase from 'firebase';
import { makeStyles } from '@material-ui/core';

const uiConfig = {
  signInFlow: 'redirect',
  signInSuccessUrl: '#/',
  signInOptions: [
    {
      provider: 'microsoft.com',
      customParameters: {
        prompt: 'login',
      },
    },
  ],
};

const useStyles = makeStyles((theme) => ({
  main: {
    background: '#334960',
    justifyContent: 'center',
  },
  card: {
    background: '#ffffff',
    padding: '25px',
    position: 'relative',
    top: '-50px',
  },
  logo: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
}));

const SignInScreen = () => (
  <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
);

const CustomLoginForm = (props: LoginProps) => {
  return (
    <div>
      <img
        src='https://www.travel2bali.dk/wp-content/themes/travel2bali/inc/files/travel2bali_logo.svg'
        height='125px'
        alt=''
        style={{ padding: '10px' }}
      />
      <SignInScreen />
    </div>
  );
};
const LoginPage = (props: LoginProps) => {
  const classes = useStyles();
  return (
    <Login {...props} classes={classes} backgroundImage=''>
      <CustomLoginForm {...props} />
    </Login>
  );
};

export default LoginPage;
