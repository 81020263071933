import { List, BooleanField, Datagrid, DatagridProps, useAuthState, Loading, downloadCSV, TextField, TextInput, FunctionField, Button } from 'react-admin';
import { useMediaQuery, useTheme } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import jsonExport from 'jsonexport/dist';

const exporter = (records: any[]) => {
  jsonExport(records, { rowDelimiter: ';' }, (err, csv) => {
    downloadCSV(csv, 'hotels');
  });
};

const filters = [<TextInput source='title' label='Title' alwaysOn />];

const HotelList = (props: DatagridProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  if (isSmall) {
    return (
      <List {...props} sort={{ field: 'title', order: 'ASC' }} bulkActionButtons={false}>
        <Datagrid rowClick='show'>
          <TextField source='title' label='Title' />
          <BooleanField source='visible' label='Visible' />

          <FunctionField
            render={(record: any) => (
              <a href={`https://www.travel2bali.dk/${record.name}`} rel='noopener noreferrer' target='_blank'>
                <Button label=''>
                  <LinkIcon />
                </Button>
              </a>
            )}
          />
        </Datagrid>
      </List>
    );
  }

  if (isMedium) {
    return (
      <List {...props} sort={{ field: 'title', order: 'ASC' }} filters={filters} bulkActionButtons={false} exporter={exporter} perPage={25}>
        <Datagrid rowClick='show'>
          <TextField source='title' label='Title' />
          <BooleanField source='visible' label='Visible' />

          <FunctionField
            render={(record: any) => (
              <a href={`https://www.travel2bali.dk/${record.name}`} rel='noopener noreferrer' target='_blank'>
                <Button label=''>
                  <LinkIcon />
                </Button>
              </a>
            )}
          />
        </Datagrid>
      </List>
    );
  }

  return (
    <List {...props} sort={{ field: 'title', order: 'ASC' }} filters={filters} bulkActionButtons={false} exporter={exporter} perPage={25}>
      <Datagrid rowClick='show'>
        <TextField source='title' label='Title' />
        <BooleanField source='visible' label='Visible' />

        <FunctionField
          render={(record: any) => (
            <a href={`https://www.travel2bali.dk/${record.name}`} rel='noopener noreferrer' target='_blank'>
              <Button label=''>
                <LinkIcon />
              </Button>
            </a>
          )}
        />
      </Datagrid>
    </List>
  );
};

export default HotelList;
