import { AutocompleteInput, DateInput, maxValue, minValue, NumberInput, ReferenceInput, required, SimpleForm } from 'react-admin';
import useQuery from '../../utils/useQuery';

const HotelPriceFormFields = (props: any) => {
  return (
    <>
      <ReferenceInput
        label='Hotel'
        source='hotel'
        reference='hotels'
        sort={{ field: 'title', order: 'ASC' }}
        perPage={1000}
        filterToQuery={() => {}}
        validate={[required()]}
        fullWidth
      >
        <AutocompleteInput optionText='title' />
      </ReferenceInput>
      <DateInput source='from_date' label='From date' validate={[required()]} fullWidth />
      <DateInput source='to_date' label='To date' validate={[required()]} fullWidth />

      <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        source='price_one_adult'
        label='Price one adult'
        validate={[required(), minValue(0)]}
        fullWidth
        step={0.1}
      />
      <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        source='price_two_adults'
        label='Price two adults'
        validate={[required(), minValue(0)]}
        fullWidth
        step={0.1}
      />
      <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        source='price_extra_adults'
        label='Price extra adults'
        validate={[required(), minValue(0)]}
        fullWidth
        step={0.1}
      />
      <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        source='price_extra_children'
        label='Price extra children'
        validate={[required(), minValue(0)]}
        fullWidth
        step={0.1}
      />
      <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        source='room_capacity'
        label='Room capacity'
        validate={[required(), minValue(1)]}
        fullWidth
        step={1}
      />
      <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        source='variable_advance_per_person'
        label='Advance in % per person'
        validate={[required(), minValue(0), maxValue(100)]}
        fullWidth
        step={0.1}
      />
      <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        source='fixed_advance_per_person'
        label='Fixed advance per person'
        validate={[required(), minValue(0)]}
        fullWidth
        step={0.1}
      />
    </>
  );
};

const HotelPriceForm = (props: any) => {
  const query = useQuery();
  const hotel = query.get('hotel') ? query.get('hotel') : undefined;
  const source = query.get('source') ? query.get('source') : undefined;
  const next = source ? `/hotels/${JSON.parse(source).hotel}/show` : query.get('next') ? query.get('next') : 'edit';

  return (
    <SimpleForm
      {...props}
      warnWhenUnsavedChanges
      sanitizeEmptyValues={false}
      initialValues={{
        hotel,
      }}
      redirect={next}
    >
      <HotelPriceFormFields />
    </SimpleForm>
  );
};

export default HotelPriceForm;
export { HotelPriceForm, HotelPriceFormFields };
