import {
  Show,
  TextField,
  ShowProps,
  useAuthState,
  Loading,
  ListButton,
  TopToolbar,
  ShowActionsProps,
  Datagrid,
  NumberField,
  SimpleShowLayout,
  ArrayField,
  ChipField,
  SingleFieldList,
  ReferenceField,
  ReferenceArrayField,
  EditButton,
  FunctionField,
} from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

const Actions = (props: ShowActionsProps) => (
  <TopToolbar>
    <ListButton basePath={props.basePath} label='Back' icon={<ChevronLeft />} />
    <EditButton basePath={props.basePath} record={props.data} />
  </TopToolbar>
);

const TripSections = (props: any) => {
  return (
    <>
      <ArrayField source='sections' label='Sections' fullWidth>
        <Datagrid
          rowClick={(id, basePath, record) => `${basePath}/${props.record.id}`}
          basePath={props.basePath}
        >
          <FunctionField
            label='Type'
            render={(record: any) =>
              record.type.charAt(0).toUpperCase() + record.type.slice(1)
            }
          />
          <FunctionField
            label='Title'
            render={(record: any) =>
              record.type === 'destination' ? (
                <ReferenceField
                  source='destination'
                  reference='destinations'
                  label='Destination'
                  link={false}
                >
                  <TextField source='title' />
                </ReferenceField>
              ) : (
                <ReferenceField
                  source='sightseeing'
                  reference='sightseeings'
                  label='Sightseeing'
                  link={false}
                >
                  <TextField source='title' />
                </ReferenceField>
              )
            }
          />
          <ReferenceField
            source='hotel'
            reference='hotels'
            label='Hotel'
            link={false}
          >
            <TextField source='title' />
          </ReferenceField>
          <NumberField source='nights' label='Nights' />
          <ReferenceArrayField
            source='excursions'
            reference='excursions'
            label='Excursions'
            fullWidth
          >
            <SingleFieldList>
              <ChipField source='title' />
            </SingleFieldList>
          </ReferenceArrayField>
        </Datagrid>
      </ArrayField>
    </>
  );
};

const TripShow = (props: ShowProps) => {
  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  return (
    <Show {...props} actions={<Actions />}>
      <SimpleShowLayout>
        <TextField source='title' />

        <TripSections />
      </SimpleShowLayout>
    </Show>
  );
};

export default TripShow;
export { TripSections, TripShow };
