import {
  List,
  Datagrid,
  DatagridProps,
  useAuthState,
  Loading,
  downloadCSV,
  TextField,
  TextInput,
  Button,
  FunctionField,
} from 'react-admin';
import { useMediaQuery, useTheme } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import jsonExport from 'jsonexport/dist';

const exporter = (records: any[]) => {
  jsonExport(records, { rowDelimiter: ';' }, (err, csv) => {
    downloadCSV(csv, 'excursions');
  });
};

const filters = [<TextInput source='title' label='Title' alwaysOn />];

const ExcursionList = (props: DatagridProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  if (isSmall) {
    return (
      <List
        {...props}
        sort={{ field: 'title', order: 'ASC' }}
        bulkActionButtons={false}
      >
        <Datagrid rowClick='show'>
          <TextField source='title' label='Title' />

          <FunctionField
            render={(record: any) => (
              <a
                href={`https://www.travel2bali.dk/${record.name}`}
                rel='noopener noreferrer'
                target='_blank'
              >
                <Button label=''>
                  <LinkIcon />
                </Button>
              </a>
            )}
          />
        </Datagrid>
      </List>
    );
  }

  if (isMedium) {
    return (
      <List
        {...props}
        sort={{ field: 'title', order: 'ASC' }}
        filters={filters}
        bulkActionButtons={false}
        exporter={exporter}
        perPage={25}
      >
        <Datagrid rowClick='show'>
          <TextField source='title' label='Title' />

          <FunctionField
            render={(record: any) => (
              <a
                href={`https://www.travel2bali.dk/${record.name}`}
                rel='noopener noreferrer'
                target='_blank'
              >
                <Button label=''>
                  <LinkIcon />
                </Button>
              </a>
            )}
          />
        </Datagrid>
      </List>
    );
  }

  return (
    <List
      {...props}
      sort={{ field: 'title', order: 'ASC' }}
      filters={filters}
      bulkActionButtons={false}
      exporter={exporter}
      perPage={25}
    >
      <Datagrid rowClick='show'>
        <TextField source='title' label='Title' />

        <FunctionField
          render={(record: any) => (
            <a
              href={`https://www.travel2bali.dk/${record.name}`}
              rel='noopener noreferrer'
              target='_blank'
            >
              <Button label=''>
                <LinkIcon />
              </Button>
            </a>
          )}
        />
      </Datagrid>
    </List>
  );
};

export default ExcursionList;
