import { Route } from 'react-router-dom';
import SynchronizeData from './wordpress/SynchronizeData';
import DeployBookingSite from './deployment/DeployBookingSite';

const CustomRoutes = [
  <Route
    exact
    path='/wordpress/synchronize-data'
    component={SynchronizeData}
  />,
  <Route exact path='/deployment/booking-site' component={DeployBookingSite} />,
];

export default CustomRoutes;
