import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Title,
  Loading,
  useAuthState,
  useNotify,
  fetchStart,
  fetchEnd,
  Button,
} from 'react-admin';
import { Box } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import firebase from 'firebase';
import 'firebase/functions';

const SynchronizeData = () => {
  const { loading, authenticated } = useAuthState();
  const [deploying, setDeploying] = useState(false);
  const dispatch = useDispatch();
  const notify = useNotify();

  const firebaseFunctions = firebase.app().functions('europe-west1');
  const deployBookingSiteFunction =
    firebaseFunctions.httpsCallable('deployBookingSite');

  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  const syncData = async () => {
    setDeploying(true);
    dispatch(fetchStart());

    try {
      await deployBookingSiteFunction();
      notify(
        'Deployment has started, which takes approx. 2-3 minutes',
        'success',
      );
    } catch (error) {
      notify('Deployment failed to start', 'error');
    }

    setDeploying(false);
    dispatch(fetchEnd());
  };

  if (deploying) return <Loading />;

  return (
    <Card>
      <Title title='Deploy Booking Site' />
      <CardContent>
        <Box
          textAlign='center'
          style={{ paddingTop: '40px', paddingBottom: '40px' }}
        >
          <Button
            variant='contained'
            label='Start deployment of booking site'
            size='large'
            onClick={() => syncData()}
          >
            <SendIcon />
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SynchronizeData;
