import { DateInput, maxValue, minValue, NumberInput, required, SelectInput, SimpleForm } from 'react-admin';
// import { JsonInput } from 'react-admin-json-view';

const FlightFormFields = (props: any) => {
  return (
    <>
      <SelectInput
        source='type'
        label='Type'
        choices={[
          { id: 'period', name: 'Period' },
          { id: 'scheduled_trip', name: 'Scheduled trip' },
        ]}
        validate={[required()]}
        fullWidth
      />
      <DateInput source='from_date' label='From date' validate={[required()]} fullWidth />
      <DateInput source='to_date' label='To date' validate={[required()]} fullWidth />

      <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        source='price_per_adult'
        label='Price per adult'
        validate={[required(), minValue(0)]}
        fullWidth
        step={0.1}
      />
      <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        source='price_per_child'
        label='Price per child'
        validate={[required(), minValue(0)]}
        fullWidth
        step={0.1}
      />

      <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        source='variable_advance_per_person'
        label='Advance in % per person'
        validate={[required(), minValue(0), maxValue(100)]}
        fullWidth
        step={0.1}
      />
      <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        source='fixed_advance_per_person'
        label='Fixed advance per person'
        validate={[required(), minValue(0)]}
        fullWidth
        step={0.1}
      />

      {/* <JsonInput source='request' validate={[required()]} />
      <JsonInput source='response' validate={[required()]} /> */}
    </>
  );
};

const FlightForm = (props: any) => {
  return (
    <SimpleForm {...props} warnWhenUnsavedChanges sanitizeEmptyValues={false}>
      <FlightFormFields />
    </SimpleForm>
  );
};

export default FlightForm;
export { FlightForm, FlightFormFields };
