import {
  Show,
  SimpleShowLayout,
  TextField,
  ShowProps,
  useAuthState,
  BooleanField,
  Loading,
  ListButton,
  TopToolbar,
  ShowActionsProps,
  ReferenceManyField,
  Datagrid,
  DateField,
  Pagination,
  NumberField,
  CloneButton,
  DeleteButton,
  Record,
  RowClickFunction,
  Identifier,
  EditButton,
} from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import AddRelatedButton from '../generic/AddRelatedButton';

const Actions = (props: ShowActionsProps) => (
  <TopToolbar>
    <ListButton basePath={props.basePath} label='Back' icon={<ChevronLeft />} />
    <EditButton basePath={props.basePath} record={props.data} />
  </TopToolbar>
);

const onRowClick: RowClickFunction = (id: Identifier, basePath: string, record: Record) => {
  return `${basePath}/${id}?next=/hotels/${record.hotel}/show`;
};

const HotelShow = (props: ShowProps) => {
  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  return (
    <Show {...props} actions={<Actions />}>
      <SimpleShowLayout>
        <TextField source='title' />

        <BooleanField source='visible' label='Visible' />

        <ReferenceManyField
          reference='hotel_prices'
          target='hotel'
          label='Prices'
          sort={{ field: 'from_date', order: 'ASC' }}
          pagination={<Pagination />}
          perPage={25}
          fullWidth
        >
          <Datagrid rowClick={onRowClick}>
            <DateField source='from_date' label='From date' locales='en-GB' />
            <DateField source='to_date' label='To date' locales='en-GB' />
            <NumberField source='price_one_adult' label='Price one adult' />
            <NumberField source='price_two_adults' label='Price two adults' />
            <NumberField source='price_extra_adults' label='Price extra adults' />
            <NumberField source='price_extra_children' label='Price extra children' />

            <NumberField source='room_capacity' label='Room capacity' />

            <NumberField source='variable_advance_per_person' label='Advance in % per person' />
            <NumberField source='fixed_advance_per_person' label='Fixed advance per person' />

            <CloneButton label='' />
            <DeleteButton label='' mutationMode='optimistic' redirect={`${props.basePath}/${props.id}/show`} />
          </Datagrid>
        </ReferenceManyField>
        <AddRelatedButton originResource='hotels' originField='hotel' originId={props.id} targetResource='hotel_prices' targetField='hotel_price' />
      </SimpleShowLayout>
    </Show>
  );
};

export default HotelShow;
