import { AutocompleteInput, DateInput, maxValue, minValue, NumberInput, ReferenceInput, required } from 'react-admin';
import { CompactForm, RaBox } from 'ra-compact-ui';
import useQuery from '../../utils/useQuery';

const SightseeingPriceFormFields = (props: any) => {
  return (
    <>
      <ReferenceInput
        label='Sightseeing'
        source='sightseeing'
        reference='sightseeings'
        sort={{ field: 'title', order: 'ASC' }}
        perPage={1000}
        filterToQuery={() => {}}
        validate={[required()]}
        fullWidth
      >
        <AutocompleteInput optionText='title' />
      </ReferenceInput>
      <DateInput source='from_date' label='From date' validate={[required()]} fullWidth />
      <DateInput source='to_date' label='To date' validate={[required()]} fullWidth />

      <RaBox display='flex' justifyContent='space-around'>
        <NumberInput
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          source='price_one_adult'
          label='Price one adult'
          validate={[required(), minValue(0)]}
          fullWidth
          style={{ marginRight: '10px' }}
          step={0.1}
        />
        <NumberInput
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          source='price_one_child'
          label='Price one child'
          validate={[required(), minValue(0)]}
          fullWidth
          style={{ marginLeft: '10px' }}
          step={0.1}
        />
      </RaBox>

      <RaBox display='flex' justifyContent='space-around'>
        <NumberInput
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          source='price_two_adults'
          label='Price two adults'
          validate={[required(), minValue(0)]}
          fullWidth
          style={{ marginRight: '10px' }}
          step={0.1}
        />
        <NumberInput
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          source='price_two_children'
          label='Price two children'
          validate={[required(), minValue(0)]}
          fullWidth
          style={{ marginLeft: '10px' }}
          step={0.1}
        />
      </RaBox>

      <RaBox display='flex' justifyContent='space-around'>
        <NumberInput
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          source='price_three_adults'
          label='Price three adults'
          validate={[required(), minValue(0)]}
          fullWidth
          style={{ marginRight: '10px' }}
          step={0.1}
        />
        <NumberInput
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          source='price_three_children'
          label='Price three children'
          validate={[required(), minValue(0)]}
          fullWidth
          style={{ marginLeft: '10px' }}
          step={0.1}
        />
      </RaBox>

      <RaBox display='flex' justifyContent='space-around'>
        <NumberInput
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          source='price_four_adults'
          label='Price four adults'
          validate={[required(), minValue(0)]}
          fullWidth
          style={{ marginRight: '10px' }}
          step={0.1}
        />
        <NumberInput
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          source='price_four_children'
          label='Price four children'
          validate={[required(), minValue(0)]}
          fullWidth
          style={{ marginLeft: '10px' }}
          step={0.1}
        />
      </RaBox>

      <RaBox display='flex' justifyContent='space-around'>
        <NumberInput
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          source='price_five_or_more_adults'
          label='Price five or more adults'
          validate={[required(), minValue(0)]}
          fullWidth
          style={{ marginRight: '10px' }}
          step={0.1}
        />
        <NumberInput
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          source='price_five_or_more_children'
          label='Price five or more children'
          validate={[required(), minValue(0)]}
          fullWidth
          style={{ marginLeft: '10px' }}
          step={0.1}
        />
      </RaBox>

      <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        source='variable_advance_per_person'
        label='Advance in % per person'
        validate={[required(), minValue(0), maxValue(100)]}
        fullWidth
        step={0.1}
      />
      <NumberInput
        onWheel={(e) => (e.target as HTMLInputElement).blur()}
        source='fixed_advance_per_person'
        label='Fixed advance per person'
        validate={[required(), minValue(0)]}
        fullWidth
        step={0.1}
      />
    </>
  );
};

const SightseeingPriceForm = (props: any) => {
  const query = useQuery();
  const sightseeing = query.get('sightseeing') ? query.get('sightseeing') : undefined;
  const source = query.get('source') ? query.get('source') : undefined;
  const next = source ? `/sightseeings/${JSON.parse(source).sightseeing}/show` : query.get('next') ? query.get('next') : 'edit';

  const initialValues: any = {
    sightseeing,
  };

  if (!props.record.id) {
    if (!source || !JSON.parse(source).from_date) {
      initialValues.from_date = '2022-01-10';
    }
    if (!source || !JSON.parse(source).to_date) {
      initialValues.to_date = '2023-01-09';
    }

    if (!source || !JSON.parse(source).price_one_adult) {
      initialValues.price_one_adult = 0;
    }
    if (!source || !JSON.parse(source).price_two_adults) {
      initialValues.price_two_adults = 0;
    }
    if (!source || !JSON.parse(source).price_three_adults) {
      initialValues.price_three_adults = 0;
    }
    if (!source || !JSON.parse(source).price_four_adults) {
      initialValues.price_four_adults = 0;
    }
    if (!source || !JSON.parse(source).price_five_or_more_adults) {
      initialValues.price_five_or_more_adults = 0;
    }

    if (!source || !JSON.parse(source).price_one_child) {
      initialValues.price_one_child = 0;
    }
    if (!source || !JSON.parse(source).price_two_children) {
      initialValues.price_two_children = 0;
    }
    if (!source || !JSON.parse(source).price_three_children) {
      initialValues.price_three_children = 0;
    }
    if (!source || !JSON.parse(source).price_four_children) {
      initialValues.price_four_children = 0;
    }
    if (!source || !JSON.parse(source).price_five_or_more_children) {
      initialValues.price_five_or_more_children = 0;
    }

    if (!source || !JSON.parse(source).price_four_children) {
      initialValues.variable_advance_per_person = 0;
    }
    if (!source || !JSON.parse(source).price_five_or_more_children) {
      initialValues.fixed_advance_per_person = 0;
    }
  }

  return (
    <CompactForm {...props} warnWhenUnsavedChanges sanitizeEmptyValues={false} initialValues={initialValues} redirect={next} layoutComponents={[RaBox]}>
      <SightseeingPriceFormFields />
    </CompactForm>
  );
};

export default SightseeingPriceForm;
export { SightseeingPriceForm, SightseeingPriceFormFields };
