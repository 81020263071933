import {
  List,
  Datagrid,
  DatagridProps,
  useAuthState,
  Loading,
  downloadCSV,
  TextField,
  DateField,
  NumberField,
} from 'react-admin';
import { useMediaQuery, useTheme } from '@material-ui/core';
import jsonExport from 'jsonexport/dist';

const exporter = (records: any[]) => {
  jsonExport(records, { rowDelimiter: ';' }, (err, csv) => {
    downloadCSV(csv, 'excursions');
  });
};

const filters: any[] = [];

const BookingList = (props: DatagridProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  if (isSmall) {
    return (
      <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick='show'>
          <DateField source='created_at' label='Created at' locales='en-GB' />
          <TextField source='title' label='Title' />
        </Datagrid>
      </List>
    );
  }

  if (isMedium) {
    return (
      <List
        {...props}
        sort={{ field: 'created_at', order: 'DESC' }}
        filters={filters}
        bulkActionButtons={false}
        exporter={exporter}
        perPage={25}
      >
        <Datagrid rowClick='show'>
          <DateField source='created_at' label='Created at' locales='en-GB' />
          <TextField source='trip.contactInfo.first_name' label='First name' />
          <TextField source='trip.contactInfo.last_name' label='Last name' />
          <TextField source='trip.contactInfo.email' label='Email' />
          <TextField source='trip.title' label='Title' />
        </Datagrid>
      </List>
    );
  }

  return (
    <List
      {...props}
      sort={{ field: 'created_at', order: 'DESC' }}
      filters={filters}
      exporter={exporter}
      perPage={25}
    >
      <Datagrid rowClick='show'>
        <DateField source='created_at' label='Created at' locales='en-GB' />
        <TextField source='trip.contactInfo.first_name' label='First name' />
        <TextField source='trip.contactInfo.last_name' label='Last name' />
        <TextField source='trip.contactInfo.email' label='Email' />
        <TextField source='trip.contactInfo.phone_no' label='Phone No.' />
        <TextField source='trip.title' label='Title' />
        <DateField
          source='trip.departureDate'
          label='Departure date'
          locales='en-GB'
        />
        <DateField
          source='trip.arrivalDate'
          label='Arrival date'
          locales='en-GB'
        />
        <NumberField source='trip.days' label='Days' />
        <NumberField source='trip.adults' label='Adults' />
        <NumberField source='trip.children' label='Children' />
      </Datagrid>
    </List>
  );
};

export default BookingList;
