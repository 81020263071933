import {
  List,
  Datagrid,
  TextField,
  DatagridProps,
  TextInput,
  useAuthState,
  Loading,
  downloadCSV,
} from 'react-admin';
import { useMediaQuery, useTheme } from '@material-ui/core';
import jsonExport from 'jsonexport/dist';

const exporter = (records: any[]) => {
  jsonExport(records, { rowDelimiter: ';' }, (err, csv) => {
    downloadCSV(csv, 'users');
  });
};

const filters = [<TextInput label='Email' source='id' alwaysOn />];

const UserList = (props: DatagridProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  if (isSmall) {
    return (
      <List {...props} sort={{ field: 'id', order: 'ASC' }}>
        <Datagrid>
          <TextField source='id' />
        </Datagrid>
      </List>
    );
  }

  return (
    <List
      {...props}
      sort={{ field: 'id', order: 'ASC' }}
      filters={filters}
      exporter={exporter}
      perPage={25}
    >
      <Datagrid>
        <TextField label='Email' source='id' />
      </Datagrid>
    </List>
  );
};

export default UserList;
