import {
  List,
  Datagrid,
  DatagridProps,
  useAuthState,
  Loading,
  downloadCSV,
  TextField,
  TextInput,
} from 'react-admin';
import { useMediaQuery, useTheme } from '@material-ui/core';
import jsonExport from 'jsonexport/dist';

const exporter = (records: any[]) => {
  jsonExport(records, { rowDelimiter: ';' }, (err, csv) => {
    downloadCSV(csv, 'sightseeings');
  });
};

const filters = [<TextInput source='title' label='Title' alwaysOn />];

const SightseeingList = (props: DatagridProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  if (isSmall) {
    return (
      <List
        {...props}
        sort={{ field: 'title', order: 'ASC' }}
        bulkActionButtons={false}
      >
        <Datagrid rowClick='show'>
          <TextField source='title' label='Title' />
        </Datagrid>
      </List>
    );
  }

  if (isMedium) {
    return (
      <List
        {...props}
        sort={{ field: 'title', order: 'ASC' }}
        filters={filters}
        bulkActionButtons={false}
        exporter={exporter}
        perPage={25}
      >
        <Datagrid rowClick='show'>
          <TextField source='title' label='Title' />
        </Datagrid>
      </List>
    );
  }

  return (
    <List
      {...props}
      sort={{ field: 'title', order: 'ASC' }}
      filters={filters}
      bulkActionButtons={false}
      exporter={exporter}
      perPage={25}
    >
      <Datagrid rowClick='show'>
        <TextField source='title' label='Title' />
      </Datagrid>
    </List>
  );
};

export default SightseeingList;
