import { Admin, Resource } from 'react-admin';
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from 'react-admin-firebase';
import { createTheme } from '@material-ui/core/styles';

import config from './firebase/config';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import ExploreIcon from '@material-ui/icons/Explore';
import LandscapeIcon from '@material-ui/icons/Landscape';
import LocalAirportIcon from '@material-ui/icons/LocalAirport';
import HotelIcon from '@material-ui/icons/Hotel';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import EmailIcon from '@material-ui/icons/Email';

import LoginPage from './components/login/LoginPage';

import Dashboard from './components/dashboard/Dashboard';

import CustomLayout from './components/CustomLayout';
import CustomRoutes from './components/CustomRoutes';

import BookingList from './components/bookings/BookingList';
import BookingShow from './components/bookings/BookingShow';

import ExcursionList from './components/excursions/ExcursionList';
import ExcursionShow from './components/excursions/ExcursionShow';

import ExcursionPriceCreate from './components/excursion_prices/ExcursionPriceCreate';
import ExcursionPriceEdit from './components/excursion_prices/ExcursionPriceEdit';
import ExcursionPriceList from './components/excursion_prices/ExcursionPriceList';

import FlightCreate from './components/flights/FlightCreate';
import FlightEdit from './components/flights/FlightEdit';
import FlightList from './components/flights/FlightList';

import HotelShow from './components/hotels/HotelShow';
import HotelEdit from './components/hotels/HotelEdit';
import HotelList from './components/hotels/HotelList';

import HotelPriceCreate from './components/hotel_prices/HotelPriceCreate';
import HotelPriceEdit from './components/hotel_prices/HotelPriceEdit';
import HotelPriceList from './components/hotel_prices/HotelPriceList';

import SightseeingList from './components/sightseeings/SightseeingList';
import SightseeingShow from './components/sightseeings/SightseeingShow';

import SightseeingPriceCreate from './components/sightseeing_prices/SightseeingPriceCreate';
import SightseeingPriceEdit from './components/sightseeing_prices/SightseeingPriceEdit';
import SightseeingPriceList from './components/sightseeing_prices/SightseeingPriceList';

import TripEdit from './components/trips/TripEdit';
import TripList from './components/trips/TripList';
import TripShow from './components/trips/TripShow';

import UserList from './components/users/UserList';
import UserCreate from './components/users/UserCreate';

import SettingEdit from './components/settings/SettingEdit';

const dataProvider = FirebaseDataProvider(config, {
  renameMetaFields: {
    created_at: 'created_at',
    created_by: 'created_by',
    updated_at: 'updated_at',
    updated_by: 'updated_by',
  },
});
const authProvider = FirebaseAuthProvider(config, {});

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#334960',
    },
    secondary: {
      main: '#ff9000',
    },
  },
});

function App() {
  return (
    <Admin
      title='Travel2Bali Booking Admin'
      authProvider={authProvider}
      dataProvider={dataProvider}
      loginPage={LoginPage}
      dashboard={Dashboard}
      theme={theme}
      layout={CustomLayout}
      customRoutes={CustomRoutes}
      disableTelemetry
    >
      <Resource
        name='bookings'
        options={{ label: 'Bookings' }}
        icon={EmailIcon}
        list={BookingList}
        show={BookingShow}
      />
      <Resource
        name='excursions'
        options={{ label: 'Excursions' }}
        icon={AirportShuttleIcon}
        list={ExcursionList}
        show={ExcursionShow}
      />
      <Resource
        name='excursion_prices'
        options={{ label: 'Excursion prices' }}
        icon={MonetizationOnIcon}
        create={ExcursionPriceCreate}
        edit={ExcursionPriceEdit}
        list={ExcursionPriceList}
      />
      <Resource
        name='flights'
        options={{ label: 'Flights' }}
        icon={LocalAirportIcon}
        create={FlightCreate}
        edit={FlightEdit}
        list={FlightList}
      />
      <Resource
        name='hotels'
        options={{ label: 'Hotels' }}
        icon={HotelIcon}
        edit={HotelEdit}
        list={HotelList}
        show={HotelShow}
      />
      <Resource
        name='hotel_prices'
        options={{ label: 'Hotel prices' }}
        icon={MonetizationOnIcon}
        create={HotelPriceCreate}
        edit={HotelPriceEdit}
        list={HotelPriceList}
      />
      <Resource
        name='sightseeings'
        options={{ label: 'Sightseeings' }}
        icon={LandscapeIcon}
        list={SightseeingList}
        show={SightseeingShow}
      />
      <Resource
        name='sightseeing_prices'
        options={{ label: 'Sightseeing prices' }}
        icon={MonetizationOnIcon}
        create={SightseeingPriceCreate}
        edit={SightseeingPriceEdit}
        list={SightseeingPriceList}
      />
      <Resource
        name='trips'
        options={{ label: 'Trips' }}
        icon={ExploreIcon}
        edit={TripEdit}
        list={TripList}
        show={TripShow}
      />
      <Resource
        name='users'
        options={{ label: 'Users' }}
        icon={AccountBoxIcon}
        create={UserCreate}
        list={UserList}
      />

      <Resource name='settings' edit={SettingEdit} />
      <Resource name='destinations' />
    </Admin>
  );
}

export default App;
