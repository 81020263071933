import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Title,
  Loading,
  useAuthState,
  useNotify,
  fetchStart,
  fetchEnd,
  Button,
} from 'react-admin';
import { Box } from '@material-ui/core';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import firebase from 'firebase';
import 'firebase/functions';

const SynchronizeData = () => {
  const { loading, authenticated } = useAuthState();
  const [synchronizing, setSynchronizing] = useState(false);
  const dispatch = useDispatch();
  const notify = useNotify();

  const firebaseFunctions = firebase.app().functions('europe-west1');
  const syncDataFunction = firebaseFunctions.httpsCallable('syncAll');

  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  const syncData = async () => {
    setSynchronizing(true);
    dispatch(fetchStart());

    try {
      await syncDataFunction();
      notify('Wordpress data synchronized', 'success');
    } catch (error) {
      notify('Wordpress synchronization failed', 'error');
    }

    setSynchronizing(false);
    dispatch(fetchEnd());
  };

  if (synchronizing) return <Loading />;

  return (
    <Card>
      <Title title='Wordpress Synchronize Data' />
      <CardContent>
        <Box
          textAlign='center'
          style={{ paddingTop: '40px', paddingBottom: '40px' }}
        >
          <Button
            variant='contained'
            label='Synchronize Wordpress Data'
            size='large'
            onClick={() => syncData()}
          >
            <SyncAltIcon />
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SynchronizeData;
