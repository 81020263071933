import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  FormDataConsumer,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  minValue,
} from 'react-admin';

const TripFormFields = (props: any) => {
  return (
    <>
      <TextField source='title' label='Title' fullWidth />

      <ArrayInput source='sections' label='Sections'>
        <SimpleFormIterator>
          {/* <TripFormSectionFields {...props} /> */}
          <SelectInput
            source='type'
            label='Type'
            choices={[
              { id: 'destination', name: 'Destination' },
              { id: 'sightseeing', name: 'Sightseeing' },
            ]}
            validate={[required()]}
            fullWidth
          />
          <FormDataConsumer>
            {({
              formData, // The whole form data
              scopedFormData, // The data for this item of the ArrayInput
              getSource, // A function to get the valid source inside an ArrayInput
              ...rest
            }) =>
              scopedFormData && scopedFormData.type && scopedFormData.type === 'destination' && getSource ? (
                <>
                  <ReferenceInput
                    label='Title'
                    source={getSource('destination')}
                    reference='destinations'
                    sort={{ field: 'title', order: 'ASC' }}
                    perPage={1000}
                    filterToQuery={() => {}}
                    validate={[required()]}
                    fullWidth
                  >
                    <AutocompleteInput optionText='title' />
                  </ReferenceInput>
                  <ReferenceInput
                    label='Hotel'
                    source={getSource('hotel')}
                    reference='hotels'
                    sort={{ field: 'title', order: 'ASC' }}
                    perPage={1000}
                    filterToQuery={() => {}}
                    validate={[required()]}
                    fullWidth
                  >
                    <AutocompleteInput optionText='title' />
                  </ReferenceInput>
                  <NumberInput
                    onWheel={(e) => (e.target as HTMLInputElement).blur()}
                    label='Nights'
                    source={getSource('nights')}
                    validate={[required(), minValue(1)]}
                    fullWidth
                    step={1}
                  />
                  <ReferenceArrayInput
                    label='Excursions'
                    source={getSource('excursions')}
                    reference='excursions'
                    sort={{ field: 'title', order: 'ASC' }}
                    perPage={1000}
                    filterToQuery={() => {}}
                    fullWidth
                  >
                    <AutocompleteArrayInput optionText={'title'} />
                  </ReferenceArrayInput>
                </>
              ) : scopedFormData && scopedFormData.type && scopedFormData.type === 'sightseeing' && getSource ? (
                <>
                  <ReferenceInput
                    label='Sightseeing'
                    source={getSource('sightseeing')}
                    reference='sightseeings'
                    sort={{ field: 'title', order: 'ASC' }}
                    perPage={1000}
                    filterToQuery={() => {}}
                    validate={[required()]}
                    fullWidth
                  >
                    <AutocompleteInput optionText='title' />
                  </ReferenceInput>
                </>
              ) : (
                <></>
              )
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

const TripForm = (props: any) => {
  return (
    <SimpleForm {...props} warnWhenUnsavedChanges sanitizeEmptyValues={false}>
      <TripFormFields />
    </SimpleForm>
  );
};

export default TripForm;
export { TripForm, TripFormFields };
