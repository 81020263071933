import {
  List,
  Datagrid,
  DatagridProps,
  useAuthState,
  Loading,
  DateField,
  NumberField,
  DateInput,
  downloadCSV,
  CloneButton,
  TextField,
  FunctionField,
  TextInput,
} from 'react-admin';
import { useMediaQuery, useTheme } from '@material-ui/core';
import jsonExport from 'jsonexport/dist';

const exporter = (records: any[]) => {
  jsonExport(records, { rowDelimiter: ';' }, (err, csv) => {
    downloadCSV(csv, 'sightseeing_prices');
  });
};

const filters = [
  <TextInput source='sightseeing_title' label='Sightseeing' alwaysOn />,
  <DateInput source='from_date' label='From date' />,
  <DateInput source='to_date' label='To date' />,
];

const SightseeingPriceList = (props: DatagridProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const { loading, authenticated } = useAuthState();
  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Loading />;
  }

  if (isSmall) {
    return (
      <List {...props} sort={{ field: 'sightseeing_title', order: 'ASC' }}>
        <Datagrid rowClick='edit'>
          <TextField label='Sightseeing' source='sightseeing_title' />

          <DateField source='from_date' label='From date' locales='en-GB' />
        </Datagrid>
      </List>
    );
  }

  if (isMedium) {
    return (
      <List
        {...props}
        sort={{ field: 'sightseeing_title', order: 'ASC' }}
        filters={filters}
        exporter={exporter}
        perPage={25}
      >
        <Datagrid rowClick='edit'>
          <TextField label='Sightseeing' source='sightseeing_title' />

          <DateField source='from_date' label='From date' locales='en-GB' />
          <DateField source='to_date' label='To date' locales='en-GB' />
          <NumberField source='price_one_adult' label='Price one adult' />
          <NumberField source='price_two_adults' label='Price two adults' />
        </Datagrid>
      </List>
    );
  }

  return (
    <List
      {...props}
      sort={{ field: 'sightseeing_title', order: 'ASC' }}
      filters={filters}
      exporter={exporter}
      perPage={25}
    >
      <Datagrid rowClick='edit'>
        <TextField label='Sightseeing' source='sightseeing_title' />

        <DateField source='from_date' label='From date' locales='en-GB' />
        <DateField source='to_date' label='To date' locales='en-GB' />
        <FunctionField
          source='price_one_adult'
          label='Price one adult / child'
          render={(record: any) =>
            `${record.price_one_adult
              .toLocaleString()
              .replace('.', ',')} / ${record.price_one_child
              .toLocaleString()
              .replace('.', ',')}`
          }
          textAlign='right'
        />
        <FunctionField
          source='price_two_adults'
          label='Price two adults / children'
          render={(record: any) =>
            `${record.price_two_adults
              .toLocaleString()
              .replace('.', ',')} / ${record.price_two_children
              .toLocaleString()
              .replace('.', ',')}`
          }
          textAlign='right'
        />
        <FunctionField
          source='price_three_adults'
          label='Price three adults / children'
          render={(record: any) =>
            `${record.price_three_adults
              .toLocaleString()
              .replace('.', ',')} / ${record.price_three_children
              .toLocaleString()
              .replace('.', ',')}`
          }
          textAlign='right'
        />
        <FunctionField
          source='price_four_adults'
          label='Price four adults / children'
          render={(record: any) =>
            `${record.price_four_adults
              .toLocaleString()
              .replace('.', ',')} / ${record.price_four_children
              .toLocaleString()
              .replace('.', ',')}`
          }
          textAlign='right'
        />
        <FunctionField
          source='price_five_or_more_adults'
          label='Price five or more adults / children'
          render={(record: any) =>
            `${record.price_five_or_more_adults
              .toLocaleString()
              .replace('.', ',')} / ${record.price_five_or_more_children
              .toLocaleString()
              .replace('.', ',')}`
          }
          textAlign='right'
        />
        <NumberField
          source='variable_advance_per_person'
          label='Advance in % per person'
        />
        <NumberField
          source='fixed_advance_per_person'
          label='Fixed advance per person'
        />

        <CloneButton basePath={props.basePath} label='' />
      </Datagrid>
    </List>
  );
};

export default SightseeingPriceList;
